import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { Label, Input } from '@paymongo/seeds';

import './AmountTextfieldComponent.scss';

import MainPageStore from '@stores/MainPageStore';

const amountFormat = /^[0-9]*$/;

export default observer(() => {
  const mainPageStore = useContext(MainPageStore);

  let paymentMethodText = '';

  if (mainPageStore.paymentMethod === 'gcash') {
    paymentMethodText = 'GCash';
  } else if (mainPageStore.paymentMethod === 'grab_pay') {
    paymentMethodText = 'GrabPay';
  }

  const handleOnChange = (e) => {
    const value = e.currentTarget.value;

    let error = '';

    if (value.length > 6) {
      return;
    } else if (amountFormat.test(value) || value === '') {
      mainPageStore.amount.setValue(value);
    }

    if (value === '') {
      error = 'Amount is required.';
    } else if (amountFormat.test(value) && Number.parseInt(value, 10) < 100) {
      error = 'Donation amount should be at least PHP 100.';
    } else if (
      amountFormat.test(value) &&
      mainPageStore.paymentMethod === 'card' &&
      Number.parseInt(value, 10) > 1000000
    ) {
      error = 'Donation maximum amount is Php 1,000,000.00.';
    } else if (
      amountFormat.test(value) &&
      ['gcash', 'grab_pay'].includes(mainPageStore.paymentMethod) &&
      Number.parseInt(value, 10) > 100000
    ) {
      error = `Donation maximum amount for ${paymentMethodText} is Php 100,000.00.`;
    }

    mainPageStore.amount.setError(error);
  };

  useEffect(() => {
    if (mainPageStore.paymentMethod !== '') {
      let error = '';
      const amount = mainPageStore.amount.value.toString();
      if (
        amountFormat.test(amount) &&
        mainPageStore.paymentMethod === 'card' &&
        Number.parseInt(mainPageStore.amount, 10) > 1000000
      ) {
        error = 'Donation maximum amount is Php 1,000,000.00.';
      } else if (
        amountFormat.test(amount) &&
        ['gcash', 'grab_pay'].includes(mainPageStore.paymentMethod) &&
        Number.parseInt(amount, 10) > 100000
      ) {
        error = `Donation maximum amount for ${paymentMethodText} is Php 100,000.00.`;
      } else if (
        amountFormat.test(amount) &&
        Number.parseInt(amount, 10) < 100
      ) {
        error = 'Donation amount should be at least PHP 100.';
      } else if (amount === '') {
        error = 'Amount is required.';
      }

      mainPageStore.amount.setError(error);
    }
  }, [mainPageStore.paymentMethod]);

  return (
    <>
      <Input
        className={`--ultra amount-textfield ${
          mainPageStore.amount.error ? 'invalid' : ''
        }`}
        autoFocus={true}
        disabled={mainPageStore.isLoading}
        placeholder="Minimum amount of ₱ 100"
        prefix={<span>₱</span>}
        value={mainPageStore.amount.value}
        onChange={handleOnChange}
        type="text"
      />
      {mainPageStore.amount.error && (
        <Label htmlFor="amount" className="error-label">
          {mainPageStore.amount.error}
        </Label>
      )}
    </>
  );
});
