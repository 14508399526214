import React from 'react';

import PaymentMethodMenuComponent from './components/payment_method_menu/PaymentMethodMenuComponent';

import './PaymentMethodStepComponent.scss';

export default () => {
  return (
    <div className="payment-method-step">
      <h4>Donation Method</h4>
      <PaymentMethodMenuComponent />
    </div>
  );
};
