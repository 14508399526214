import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Button, CheckBox } from '@paymongo/seeds';
import MainPageStore from '@stores/MainPageStore';

import './DonateStepComponent.scss';

export default observer(() => {
  const mainPageStore = useContext(MainPageStore);
  const [consentApprovedFlag, setConsentApprovedFlag] = useState(false);
  const [consentApprovedFlagError, setConsentApprovedFlagError] = useState('');

  const handleClick = () => {
    window._sift.push(['_setUserId', mainPageStore.email.value]);

    setConsentApprovedFlagError('');

    if (consentApprovedFlag === true) {
      mainPageStore.processDonation();
    } else {
      setConsentApprovedFlagError(
        "Please accept PayMongo's Privacy Policy to proceed."
      );
    }
  };

  useEffect(() => {
    if (mainPageStore.source.id !== '') {
      window.location.href = mainPageStore.source.checkoutUrl;
    }
  }, [mainPageStore.source.id]);

  const toggleAskConsent = () => {
    setConsentApprovedFlag(!consentApprovedFlag);
  };

  return (
    <div className="donate-step">
      <div style={{ marginBottom: '.5rem' }}>
        <CheckBox
          invalid={consentApprovedFlagError !== ''}
          onChange={toggleAskConsent}
        />{' '}
        I have read and agreed to PayMongo’s{' '}
        <a
          href="https://paymongo.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
        <div
          style={{
            color: '#fc3d31'
          }}
        >
          {consentApprovedFlagError}
        </div>
      </div>
      <Button
        onClick={handleClick}
        className="-primary donate-button"
        disabled={mainPageStore.donateButtonDisabled}
        loading={mainPageStore.isLoading}
      >
        Donate ₱ {mainPageStore.amount.value}
      </Button>
    </div>
  );
});
