import { observable, action } from 'mobx';

export default class NextActionModal {
  @observable opened = false;

  @action setOpened(value) {
    this.opened = value;
  }

  @action hide = () => {
    this.opened = false;
  };

  @action show = () => {
    this.opened = true;
  };
}
