import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { Label, Input } from '@paymongo/seeds';
import MainPageStore from '@stores/MainPageStore';

import './NameTextfieldComponent.scss';

export default observer(() => {
  const mainPageStore = useContext(MainPageStore);

  const handleOnChange = (e) => {
    const value = e.currentTarget.value;

    let error = '';

    if (value === '') {
      error = 'Name is required.';
    }

    mainPageStore.name.setError(error);
    mainPageStore.name.setValue(value);
  };

  return (
    <>
      <Input
        className={`name-textfield ${mainPageStore.name.error ? 'invalid' : ''}
      `}
        disabled={mainPageStore.isLoading}
        onChange={handleOnChange}
        placeholder="Name"
        type="text"
      />
      {mainPageStore.name.error && (
        <Label htmlFor="name" className="error-label">
          {mainPageStore.name.error}
        </Label>
      )}
    </>
  );
});
