import { action, observable } from 'mobx';

export default class Campaign {
  @observable slug = '';
  @observable image = '';
  @observable organizationTitle = '';
  @observable publicKey = '';
  @observable description = '';
  @observable name = '';

  @action setSlug(value) {
    this.slug = value;
  }

  @action setImage(value) {
    this.image = value;
  }

  @action setDescription(value) {
    this.description = value;
  }

  @action setOrganizationTitle(value) {
    this.organizationTitle = value;
  }

  @action setPublicKey(value) {
    this.publicKey = value;
  }

  @action setName(value) {
    this.name = value;
  }
}
