import React, { useContext, useEffect } from 'react';
import { RadioMenu } from '@paymongo/seeds';

import './PaymentMethodMenuComponent.scss';

import MainPageStore from '@stores/MainPageStore';
import { observer } from 'mobx-react-lite';

const paymentMethods = [
  {
    value: 'card',
    icon: 'creditCard',
    label: 'Credit/Debit Card'
  },
  {
    value: 'gcash',
    label: 'GCash',
    icon: ''
  },
  {
    value: 'grab_pay',
    label: 'GrabPay',
    icon: ''
  },
  {
    value: 'paymaya',
    label: 'Maya',
    icon: ''
  }
];

export default observer(() => {
  const mainPageStore = useContext(MainPageStore);

  const handleOnChange = (value) => {
    if (!mainPageStore.isLoading) {
      mainPageStore.setPaymentMethod(value);
    }
  };

  useEffect(() => {
    // This is a hack since paymongo-ui's radiomenu requires the radio button to be clicked.
    document.querySelectorAll('#payment-method-menu span').forEach((span) => {
      span.addEventListener('click', (e) => {
        if (!mainPageStore.isLoading) {
          const elem = e.currentTarget;

          elem.querySelector('input[type="radio"]').click();
        }
      });
    });
  }, []);

  return (
    <RadioMenu
      id="payment-method-menu"
      onChange={handleOnChange}
      className="payment-method-menu"
      items={paymentMethods}
    />
  );
});
