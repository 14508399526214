import axios from 'axios';
import Webservice from './Webservice';

export default class PaymentIntentWebservice extends Webservice {
  create({ slug, amount }) {
    return axios.post('/api/payment_intents', {
      data: {
        attributes: {
          slug,
          amount
        }
      }
    });
  }

  get({ slug, id }) {
    return axios.get(`/api/payment_intents/${id}`, {
      params: {
        slug
      }
    });
  }
}
