import { observable, action } from 'mobx';

export default class Source {
  @observable id = '';

  @observable amount = null;

  @observable status = '';

  @observable checkoutUrl = '';

  @action clearSource() {
    this.id = '';
    this.amount = null;
    this.status = '';
    this.checkoutUrl = '';
  }

  @action setAmount(val) {
    this.amount = val;
  }

  @action setId(val) {
    this.id = val;
  }

  @action setStatus(val) {
    this.status = val;
  }

  @action setCheckoutUrl(val) {
    this.checkoutUrl = val;
  }
}
