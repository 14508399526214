/* eslint-disable class-methods-use-this */
import axios from 'axios';
import Webservice from './Webservice';

export default class PaymongoPaymentIntentWebservice extends Webservice {
  attach({
    apiKey = null,
    paymentIntentId = null,
    clientKey = null,
    paymentMethodId = null,
    returnUrl
  }) {
    const payload = {
      client_key: clientKey,
      payment_method: paymentMethodId
    };

    if (returnUrl !== null || returnUrl !== undefined) {
      payload.return_url = returnUrl;
    }

    return axios.post(
      this.fullUrl(`/v1/payment_intents/${paymentIntentId}/attach`),
      {
        data: {
          attributes: payload
        }
      },
      {
        headers: {
          Authorization: `Basic ${window.btoa(apiKey)}`
        }
      }
    );
  }

  get(key, id, paymentIntentClientKey) {
    return axios.get(
      this.fullUrl(
        `/v1/payment_intents/${id}?client_key=${paymentIntentClientKey}`
      ),
      {
        headers: {
          Authorization: `Basic ${window.btoa(key)}`
        }
      }
    );
  }
}
