import React from 'react';

import { Icon } from '@paymongo/seeds';

import './CampaignNotFoundErrorComponent.scss';

export default () => {
  return (
    <div className="campaign-not-found">
      <Icon size="xxl" icon="frown" />
      <h1 className="header">Campaign not found</h1>
      <p style={{ textAlign: 'center' }}>
        For inquiries, please reach out to support@paymongo.com
      </p>
    </div>
  );
};
