import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import MainPage from './pages/main/MainPage';

import './App.scss';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:id?" component={MainPage} />
      </Switch>
    </Router>
  );
}

export default App;
