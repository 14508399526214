/* eslint-disable class-methods-use-this */
import axios from 'axios';
import Webservice from './Webservice';

export default class PaymentMethodWebservice extends Webservice {
  create(key, payload) {
    return axios.post(
      this.fullUrl('/v1/payment_methods'),
      {
        data: {
          attributes: payload
        }
      },
      {
        headers: {
          Authorization: `Basic ${window.btoa(key)}`
        }
      }
    );
  }
}
