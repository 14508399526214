import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { Label, Input } from '@paymongo/seeds';
import MainPageStore from '@stores/MainPageStore';

import './EmailTextfieldComponent.scss';

const emailFormat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default observer(() => {
  const mainPageStore = useContext(MainPageStore);

  const handleOnChange = (e) => {
    const value = e.currentTarget.value;
    let error = '';

    if (!emailFormat.test(value) && value !== '') {
      error = 'E-mail address has invalid email format.';
    } else if (value === '') {
      error = 'E-mail address is required.';
    }

    mainPageStore.email.setError(error);
    mainPageStore.email.setValue(value);
  };

  return (
    <>
      <Input
        placeholder="E-mail Address"
        onChange={handleOnChange}
        className={`email-textfield ${
          mainPageStore.email.error ? 'invalid' : ''
        }
      `}
        disabled={mainPageStore.isLoading}
        type="text"
      />
      {mainPageStore.email.error && (
        <Label htmlFor="email" className="error-label">
          {mainPageStore.email.error}
        </Label>
      )}
    </>
  );
});
