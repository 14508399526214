import axios from 'axios';
import Webservice from './Webservice';

export default class SourceWebservice extends Webservice {
  create({ type, slug, amount, name, email }) {
    return axios.post('/api/sources', {
      data: {
        attributes: {
          type,
          slug,
          amount,
          name,
          email
        }
      }
    });
  }
}
