import React, { useEffect, useState, Fragment } from 'react';
import { v5 as uuidv5 } from 'uuid';
import { observer } from 'mobx-react-lite';

import AmountStepComponent from './components/amount_step/AmountStepComponent';
import CreditCardFormComponent from './components/credit_card_form/CreditCardFormComponent';
import DonorInformationStepComponent from './components/donor_information_step/DonorInformationStepComponent';
import DonateStepComponent from './components/donate_step/DonateStepComponent';
import FooterComponent from './components/footer/FooterComponent';
import CampaignHeaderComponent from './components/campaign_header/CampaignHeaderComponent';
import NextActionModalComponent from './components/next_action_modal/NextActionModalComponent';
import PaymentMethodStepComponent from './components/payment_method_step/PaymentMethodStepComponent';
import useMainPageStore from '../../stores/useMainPageStore';

import './MainPage.scss';
import CampaignNotFoundErrorComponent from './components/campaign_not_found/CampaignNotFoundErrorComponent';
import LoadingCampaignComponent from './components/loading/LoadingCampaignComponent';
import SuccessfulDonateComponent from './components/successful_donate/SuccessfulDonateComponent';
import FailedDonateComponent from './components/failed_donate/FailedDonateComponent';
import RedirectionMessageComponent from './components/redirection_message/RedirectionMessageComponent';

const generateSessionId = () => {
  const sid = uuidv5(window.location.href + new Date(), uuidv5.URL);

  return sid;
};

export default observer(({ match }) => {
  const [pageStatus, setPageStatus] = useState('');

  const mainPageStore = useMainPageStore();
  const messageListener = (ev) => {
    if (ev.data === '3DS-authentication-complete') {
      mainPageStore.loadPaymentIntentViaPublicKey();
    }
  };

  const initializeSift = () => {
    const e = document.createElement('script');
    e.src = 'https://cdn.sift.com/s.js';
    document.body.appendChild(e);
  };

  useEffect(() => {
    if (
      mainPageStore.campaign.slug !== null &&
      mainPageStore.campaign.slug !== ''
    ) {
      // Business logic for payment intent workflow payment methods except card
      const searchParams = new URLSearchParams(window.location.search);
      const searchPaymentIntentId = searchParams.get('payment_intent_id');

      if (searchPaymentIntentId !== null) {
        mainPageStore.loadPaymentIntentViaId(searchPaymentIntentId);
      }
    }
  }, [mainPageStore.campaign.slug]);

  useEffect(() => {
    if (match && match.params && match.params.id) {
      if (/\/success$/.test(window.location.pathname)) {
        setPageStatus('success');
      }

      if (/\/failed$/.test(window.location.pathname)) {
        setPageStatus('failed');
      }

      mainPageStore.setSlug(match.params.id);
      mainPageStore.getCampaign();
    } else {
      window.location.href = 'https://paymongo.com';
    }

    mainPageStore.setSessionId(generateSessionId());
    window.addEventListener('message', messageListener, false);

    // sift
    const _sift = (window._sift = window._sift || []);
    _sift.push(['_setAccount', process.env.REACT_APP_SIFT_BEACON_KEY]);

    _sift.push(['_setSessionId', mainPageStore.sessionId]);
    _sift.push(['_trackPageview']);

    window.addEventListener('load', initializeSift, false);

    return () => {
      window.removeEventListener('message', messageListener, false);
      window.addEventListener('load', initializeSift, false);
    };
  }, []);

  useEffect(() => {
    if (
      mainPageStore.paymentIntent.nextAction !== null &&
      mainPageStore.paymentMethod === 'paymaya'
    ) {
      window.location.href =
        mainPageStore.paymentIntent.nextAction.redirect.url;
    }
  }, [mainPageStore.paymentIntent.nextAction]);

  let renderMain = (
    <Fragment>
      <AmountStepComponent />
      <DonorInformationStepComponent />
      <PaymentMethodStepComponent />
      {mainPageStore.paymentMethod === 'card' && <CreditCardFormComponent />}
      {['gcash', 'grab_pay', 'paymaya'].includes(
        mainPageStore.paymentMethod
      ) && (
        <RedirectionMessageComponent
          paymentMethod={mainPageStore.paymentMethod}
        />
      )}
      <DonateStepComponent />
    </Fragment>
  );

  if (pageStatus === 'success') {
    renderMain = <SuccessfulDonateComponent slug={mainPageStore.slug} />;
  } else if (pageStatus === 'failed') {
    renderMain = <FailedDonateComponent slug={mainPageStore.slug} />;
  }

  const renderSuccess = (
    <SuccessfulDonateComponent
      slug={mainPageStore.slug}
      amount={mainPageStore.amount.value}
    />
  );

  let content = null;

  if (mainPageStore.isFetchingCampaign) {
    content = <LoadingCampaignComponent />;
  } else {
    if (mainPageStore.campaign.slug === null) {
      content = <CampaignNotFoundErrorComponent />;
    } else if (mainPageStore.campaign.slug !== '') {
      content = (
        <Fragment>
          <CampaignHeaderComponent
            name={mainPageStore.campaign.name}
            image={mainPageStore.campaign.image}
            description={mainPageStore.campaign.description}
          />
          <div className="main-container">
            {mainPageStore.status === 'paid' ? renderSuccess : renderMain}
          </div>
        </Fragment>
      );
    }
  }

  return (
    <div className="main-page">
      {content}
      {mainPageStore.paymentMethod === 'card' && <NextActionModalComponent />}
      <FooterComponent />
    </div>
  );
});
