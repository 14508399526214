import React from 'react';

import { Button } from '@paymongo/seeds';

import './AmountButtonComponent.scss';

export default ({ position, amount, onClick, disabled }) => {
  if (!['left', 'middle', 'right'].includes(position)) {
    console.error('Invalid position for AmountButton');

    return null;
  }

  const className = position + '-amount-button';

  const formattedAmount = amount
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <Button
      className={`--small amount-button ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      ₱ {formattedAmount}
    </Button>
  );
};
