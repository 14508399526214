import React from 'react';
import AmountButtonsComponent from './components/amount_buttons/AmountButtonsComponent';

import './AmountStepComponent.scss';
import AmountTextfieldComponent from './components/amount_textfield/AmountTextfieldComponent';

export default () => {
  return (
    <div className="amount-step">
      <h4>Amount to Donate</h4>
      <AmountButtonsComponent />
      <AmountTextfieldComponent />
    </div>
  );
};
