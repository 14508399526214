import React from 'react';
import { observable, action, computed } from 'mobx';

import CampaignWebservice from '../webservices/donation/CampaignWebservice';
import PaymentIntentWebservice from '../webservices/donation/PaymentIntentWebservice';
import SourceWebservice from '../webservices/donation/SourceWebservice';
import PaymentMethodWebservice from '../webservices/paymongo/PaymentMethodWebservice';
import PaymongoPaymentIntentWebservice from '../webservices/paymongo/PaymongoPaymentIntentWebservice';

import CreditCardForm from './CreditCardForm';
import PaymentIntent from './PaymentIntent';
import Source from './Source';
import NextActionModal from './NextActionModal';
import FormField from './FormField';
import Campaign from './Campaign';

const campaignWebservice = new CampaignWebservice();
const paymentIntentWebservice = new PaymentIntentWebservice();
const sourceWebservice = new SourceWebservice();
const paymentMethodWebservice = new PaymentMethodWebservice();
const paymongoPaymentIntentWebservice = new PaymongoPaymentIntentWebservice();

class MainPageStore {
  @observable clientKey = '';

  amount = new FormField();

  email = new FormField();

  name = new FormField();

  @observable errors = {};

  @observable isLoading = false;

  @observable isFetchingCampaign = true;

  @observable organizationId = '';

  @observable organizationName = '';

  @observable publicKey = '';

  @observable paymentMethod = '';

  @observable sessionId = '';

  @observable success = null;

  @observable slug = '';

  @observable status = '';

  campaign = new Campaign();

  card = new CreditCardForm();

  nextActionModal = new NextActionModal();

  paymentIntent = new PaymentIntent();

  source = new Source();

  @action attachToPaymentIntent = async (paymentMethodId) => {
    try {
      const attachParams = {
        apiKey: this.campaign.publicKey,
        paymentIntentId: this.paymentIntent.id,
        paymentMethodId: paymentMethodId,
        clientKey: this.paymentIntent.clientKey
      };

      if (this.paymentMethod === 'paymaya') {
        attachParams.returnUrl = window.location.href;
      }

      const response = await paymongoPaymentIntentWebservice.attach(
        attachParams
      );

      return response.data.data;
    } catch (e) {
      this.setIsLoading(false);
      this.setSuccess(false);

      if (e.response.status === 400) {
        e.response.data.errors.forEach((error) => {
          if (
            error.code === 'resource_succeeded_state' ||
            error.code === 'resource_cancelled_state'
          ) {
            window.location.reload();
          } else {
            this.card.cardNumber.setError(error.detail);
          }
        });
      }

      return null;
    }
  };

  @action createSource = async () => {
    try {
      const response = await sourceWebservice.create({
        type: this.paymentMethod,
        slug: this.campaign.slug,
        amount: Number.parseInt(this.amount.value, 10),
        name: this.name.value,
        email: this.email.value
      });

      this.source.setId(response.data.id);
      this.source.setCheckoutUrl(response.data.checkoutUrl);

      return this.source;
    } catch (e) {
      this.setIsLoading(false);

      return null;
    }
  };

  @action createPaymentIntent = async () => {
    try {
      const response = await paymentIntentWebservice.create({
        slug: this.campaign.slug,
        amount: Number.parseInt(this.amount.value, 10)
      });

      this.paymentIntent.setId(response.data.id);
      this.paymentIntent.setClientKey(response.data.clientKey);

      return this.paymentIntent;
    } catch (e) {
      const errors = {};
      this.setIsLoading(false);

      if (e.response.status === 400) {
        e.response.data.errors.forEach((error) => {
          if (error.source.attribute === 'amount') {
            let amountError = '';

            if (error.code === 'parameter_below_minimum') {
              amountError = 'Donation amount should be at least PHP 100.';
            } else {
              amountError = error.detail;
            }

            this.amount.setError(amountError);
          }
          errors[error.source.attribute] = error.detail;
        });
      }

      return null;
    }
  };

  @action createPaymentMethod = async () => {
    this.card.clearErrors();
    this.paymentIntent.clearNextAction();

    const cardNumber = this.card.cardNumber.value;

    const details = {
      card: {
        card_number: cardNumber.replace(/\s/g, ''),
        exp_month:
          Number.parseInt(this.card.expMonth.value, 10) ||
          this.card.expMonth.value,
        exp_year:
          Number.parseInt(this.card.expYear.value, 10) ||
          this.card.expYear.value,
        cvc: this.card.cvc.value
      }
    };

    try {
      const response = await paymentMethodWebservice.create(
        this.campaign.publicKey,
        {
          type: this.paymentMethod,
          sid: this.sessionId,
          details: details[this.paymentMethod],
          billing: {
            name: this.name.value,
            email: this.email.value
          }
        }
      );

      return response.data.data.id;
    } catch (e) {
      this.setIsLoading(false);
      this.setSuccess(false);

      if (e.response.status === 400) {
        e.response.data.errors.forEach((error) => {
          if (error.source.pointer === 'details.exp_year') {
            let errorText = '';

            if (
              error.code === 'parameter_blank' ||
              error.code === 'parameter_invalid'
            ) {
              errorText = 'Expiry year is invalid or expired.';
            } else {
              errorText = error.detail;
            }

            this.card.expYear.setError(errorText);
          } else if (error.source.pointer === 'details.card_number') {
            let errorText = '';

            if (
              error.code === 'parameter_blank' ||
              error.code === 'parameter_format_invalid' ||
              error.code === 'parameter_invalid'
            ) {
              errorText = 'Please check your card number and try again.';
            } else {
              errorText = error.detail;
            }

            this.card.cardNumber.setError(errorText);
          } else if (error.source.pointer === 'details.exp_month') {
            let errorText = '';

            if (error.code === 'parameter_blank') {
              errorText = 'Expiry month must be between 1 to 12.';
            } else {
              errorText = error.detail;
            }

            this.card.expMonth.setError(errorText);
          } else if (error.source.pointer === 'details.cvc') {
            let errorText = '';

            if (
              error.code === 'parameter_blank' ||
              error.code === 'parameter_below_minimum'
            ) {
              errorText = 'Please input your 3-digit CVC.';
            } else {
              errorText = error.detail;
            }

            this.card.cvc.setError(errorText);
          } else {
            this.card.cardNumber.setError(error.detail);
          }
        });
      }

      return null;
    }
  };

  @action getCampaign = async () => {
    try {
      this.setIsFetchingCampaign(true);

      const response = await campaignWebservice.get(this.slug);

      const campaign = response.data;

      this.campaign.setImage(campaign.image);
      this.campaign.setSlug(campaign.slug);
      this.campaign.setDescription(campaign.description);
      this.campaign.setName(campaign.name);
      this.campaign.setPublicKey(campaign.publicKey);
      this.campaign.setOrganizationTitle(campaign.organizationTitle);

      this.setIsFetchingCampaign(false);
    } catch (e) {
      this.setIsFetchingCampaign(false);
      this.campaign.setSlug(null);
    }
  };

  @action loadPaymentIntentViaId = async (id) => {
    try {
      const response = await paymentIntentWebservice.get({
        slug: this.campaign.slug,
        id
      });

      this.paymentIntent.setId(id);
      this.paymentIntent.setClientKey(response.data.clientKey);
      this.loadPaymentIntentViaPublicKey();
    } catch (e) {
      this.setIsLoading(false);
      this.setSuccess(false);

      console.log(e);
    }
  };

  @action loadPaymentIntentViaPublicKey = async () => {
    try {
      const response = await paymongoPaymentIntentWebservice.get(
        this.campaign.publicKey,
        this.paymentIntent.id,
        this.paymentIntent.clientKey
      );

      const paymentIntentResponse = response.data.data;

      this.paymentIntent.setId(paymentIntentResponse.id);
      this.paymentIntent.setStatus(paymentIntentResponse.attributes.status);
      this.paymentIntent.setClientKey(
        paymentIntentResponse.attributes.client_key
      );
      this.paymentIntent.setLastPaymentError(
        paymentIntentResponse.attributes.last_payment_error
      );

      this.setIsLoading(false);

      if (
        this.paymentIntent.status === 'awaiting_payment_method' &&
        this.paymentIntent.lastPaymentError &&
        this.paymentIntent.lastPaymentError.failed_code !== ''
      ) {
        this.card.cardNumber.setError(
          this.paymentIntent.lastPaymentError.failed_message
        );
        this.nextActionModal.hide();
      } else if (this.paymentIntent.status === 'succeeded') {
        this.setStatus('paid');
        this.nextActionModal.hide();

        if (this.amount.value === '') {
          this.amount.setValue(
            (paymentIntentResponse.attributes.amount / 100).toFixed(2)
          );
        }
      } else if (this.paymentIntent.status === 'cancelled') {
        window.location.reload();
      } else if (this.paymentIntent.status === 'processing') {
        if (this.nextActionModal.opened === true) {
          this.nextActionModal.hide();
        }

        this.setIsLoading(true);

        setTimeout(() => {
          this.loadPaymentIntentViaPublicKey();
        }, 1500);
      }
    } catch (e) {
      this.setIsLoading(false);
      this.setSuccess(false);

      if (e.response.status === 400) {
        e.response.data.errors.forEach((error) => {
          if (
            error.code === 'resource_succeeded_state' ||
            error.code === 'resource_cancelled_state'
          ) {
            window.location.reload();
          }
        });
      }
    }
  };

  @action setClientKey(value) {
    this.clientKey = value;
  }

  @action setIsLoading(val) {
    this.isLoading = val;
  }

  @action setIsFetchingCampaign(value) {
    this.isFetchingCampaign = value;
  }

  @action setOrganizationId(value) {
    this.organizationId = value;
  }

  @action setOrganizationName(value) {
    this.organizationName = value;
  }

  @action setPaymentMethod = (value) => {
    this.paymentMethod = value;
  };

  @action setPublicKey = (value) => {
    this.publicKey = value;
  };

  @action setSessionId = (value) => {
    this.sessionId = value;
  };

  @action setStatus = (value) => {
    this.status = value;
  };

  @action setSuccess = (value) => {
    this.success = value;
  };

  @action setSlug = (value) => {
    this.slug = value;
  };

  @action processDonation = async () => {
    try {
      if (this.donateButtonDisabled) {
        return;
      }

      this.setIsLoading(true);
      this.setSuccess(null);

      if (['card', 'paymaya'].includes(this.paymentMethod)) {
        this.paymentIntent.clearPaymentIntent();

        const paymentIntent = await this.createPaymentIntent();

        if (paymentIntent === null) {
          return;
        }

        const paymentMethodId = await this.createPaymentMethod();

        if (paymentMethodId === null) return;

        const updatedPaymentIntent = await this.attachToPaymentIntent(
          paymentMethodId
        );

        if (updatedPaymentIntent === null) return;

        this.setIsLoading(false);
        this.setSuccess(true);

        this.paymentIntent.updatePaymentIntent(updatedPaymentIntent);

        if (this.paymentIntent.nextAction === null) {
          if (this.paymentIntent.status === 'succeeded') {
            this.setStatus('paid');
          }

          this.nextActionModal.hide();
        } else {
          this.nextActionModal.show();
        }
      } else if (['gcash', 'grab_pay'].includes(this.paymentMethod)) {
        this.source.clearSource();

        await this.createSource();

        this.setIsLoading(false);
      }
    } catch (e) {
      this.setIsLoading(false);
      this.setSuccess(false);

      if (e.response.status === 400) {
        e.response.data.errors.forEach((error) => {
          if (
            error.code === 'resource_succeeded_state' ||
            error.code === 'resource_cancelled_state'
          ) {
            window.location.reload();
          }
        });
      }
    }
  };

  @action restoreDefaultStore = () => {
    this.amount.setValue('');

    this.clientKey = '';

    this.email.setValue('');

    this.errors = {};

    this.name.setValue('');

    this.isLoading = false;

    this.organizationId = '';

    this.organizationName = '';

    this.publicKey = '';

    this.paymentMethod = 'card';

    this.sessionId = '';

    this.success = null;

    this.status = '';

    this.card.clearForm();

    this.nextActionModal.hide();

    this.paymentIntent.clearPaymentIntent();
  };

  @computed get donateButtonDisabled() {
    return (
      this.hasEmptyValues ||
      this.nextActionModal.opened ||
      this.isLoading ||
      (this.paymentMethod === 'card' && this.card.hasEmptyValues) ||
      this.amount.error !== '' ||
      this.email.error !== '' ||
      this.name.error !== '' ||
      this.paymentMethod === ''
    );
  }

  @computed get hasEmptyValues() {
    const emailValue = this.email.value;
    const nameValue = this.name.value;
    const amountValue = this.amount.value;

    return (
      this.paymentMethod.trim() === '' ||
      amountValue.length < 1 ||
      emailValue.trim() === '' ||
      nameValue.trim() === ''
    );
  }
}

export default React.createContext(new MainPageStore());
