import React from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from '@paymongo/seeds';
// import Modal from '../../../../components/Modal';
import { Modal } from 'antd';

import useMainPageStore from '@stores/useMainPageStore';

import './NextActionModalComponent.scss';

export default observer(() => {
  const mainPageStore = useMainPageStore();

  const renderFrame =
    mainPageStore.paymentIntent.nextAction &&
    Object.keys(mainPageStore.paymentIntent.nextAction).length > 0 &&
    mainPageStore.paymentIntent.nextAction.type === 'redirect' ? (
      <iframe
        src={mainPageStore.paymentIntent.nextAction.redirect.url}
        title="3D Secure"
        style={{
          width: '100%',
          height: '70vh',
          border: '0'
        }}
      />
    ) : (
      <div style={{ width: '100%', textAlign: 'center' }}>
        Loading Authentication URL. Please wait...
      </div>
    );

  const handleCancelClick = () => {
    mainPageStore.paymentIntent.clearNextAction();
    mainPageStore.nextActionModal.hide();
  };

  return (
    <Modal
      visible={mainPageStore.nextActionModal.opened}
      onClose={mainPageStore.nextActionModal.hide}
      maskClosable={false}
      centered
      closable={false}
      className="next-action"
      footer={[<Button onClick={handleCancelClick}>Cancel</Button>]}
    >
      {renderFrame}
    </Modal>
  );
});
