import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';

import AmountButtonComponent from './components/amount_button/AmountButtonComponent';
import MainPageStore from '@stores/MainPageStore';

import './AmountButtonsComponent.scss';

export default observer(() => {
  const mainPageStore = useContext(MainPageStore);

  const handleClick = amount => {
    return () => {
      mainPageStore.amount.setValue(amount);
      mainPageStore.amount.setError('');
    };
  };

  return (
    <div className="amount-buttons">
      <AmountButtonComponent
        amount={500}
        onClick={handleClick(500)}
        disabled={mainPageStore.isLoading}
        position="left"
      />
      <AmountButtonComponent
        amount={1000}
        disabled={mainPageStore.isLoading}
        onClick={handleClick(1000)}
        position="middle"
      />
      <AmountButtonComponent
        amount={5000}
        disabled={mainPageStore.isLoading}
        onClick={handleClick(5000)}
        position="right"
      />
    </div>
  );
});
