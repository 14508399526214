import { action, computed } from 'mobx';
import FormField from './FormField';

const numberOnlyFormat = /^[0-9]*$/;

export default class CreditCardForm {
  cardNumber = new FormField();

  cvc = new FormField();

  expMonth = new FormField();

  expYear = new FormField();

  @action setCardNumber = value => {
    let newValue = value.trim();
    newValue = value.replace(/\s/g, '');

    this.cardNumber.setError('');

    if (
      newValue &&
      (newValue.length > 16 || !numberOnlyFormat.test(newValue))
    ) {
      return;
    } else if (newValue.length > 4) {
      const valuesArr = [];
      let flag = true;
      let currentIndex = 0;
      const steps = 4;

      while (flag) {
        valuesArr.push(newValue.substr(currentIndex, steps));
        currentIndex = currentIndex + steps;
        if (currentIndex >= newValue.length) {
          flag = false;
        }
      }

      newValue = valuesArr.join(' ');
    }

    this.cardNumber.setValue(newValue);
  };

  @action setCvc = value => {
    this.cardNumber.setError('');

    if (value && (value.length > 3 || !numberOnlyFormat.test(value))) {
      return;
    }

    this.cvc.setValue(value);
  };

  @action setExpMonth = value => {
    this.cardNumber.setError('');

    let tmpValue = value;

    if (value && (value.length > 2 || !numberOnlyFormat.test(value))) {
      return;
    } else if (value > 12) {
      tmpValue = 12;
    } else if (value === '00') {
      tmpValue = '01';
    }

    this.expMonth.setValue(tmpValue);
  };

  @action setExpYear = value => {
    this.cardNumber.setError('');

    if (value && (value.length > 2 || !numberOnlyFormat.test(value))) {
      return;
    }

    this.expYear.setValue(value);
  };

  @action clearForm = () => {
    this.cardNumber.setValue('');
    this.expMonth.setValue('');
    this.expYear.setValue('');
    this.cvc.setValue('');

    this.cardNumber.setError('');
    this.expMonth.setError('');
    this.expYear.setError('');
    this.cvc.setError('');
  };

  @action clearErrors = () => {
    this.cardNumber.setError('');
    this.expMonth.setError('');
    this.expYear.setError('');
    this.cvc.setError('');
  };

  @computed get hasEmptyValues() {
    const cardNumber = this.cardNumber.value;
    const expMonth = this.expMonth.value.toString();
    const expYear = this.expYear.value.toString();
    const cvc = this.cvc.value;

    return (
      cardNumber.trim() === '' ||
      cvc.trim() === '' ||
      expMonth.trim() === '' ||
      expYear.trim() === ''
    );
  }
}
