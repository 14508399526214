import { observable, action } from 'mobx';

export default class PaymentIntent {
  @observable clientKey = '';

  @observable id = '';

  @observable lastPaymentError = null;

  @observable nextAction = null;

  @observable status = '';

  @action clearPaymentIntent() {
    this.clientKey = '';

    this.id = '';

    this.lastPaymentError = null;

    this.nextAction = null;

    this.status = '';
  }

  @action clearNextAction() {
    this.nextAction = null;
  }

  @action setClientKey(val) {
    this.clientKey = val;
  }

  @action setId(val) {
    this.id = val;
  }

  @action setLastPaymentError(val) {
    this.lastPaymentError = val;
  }

  @action setNextAction(val) {
    this.nextAction = val;
  }

  @action setStatus(val) {
    this.status = val;
  }

  @action updatePaymentIntent(data) {
    this.clientKey = data.attributes.client_key;
    this.id = data.id;
    this.lastPaymentError = data.attributes.last_payment_error;

    if (data.attributes.next_action !== null) {
      this.nextAction = {
        type: data.attributes.next_action.type || '',
        redirect: {
          url: data.attributes.next_action.redirect.url || '',
          returnUrl: data.attributes.next_action.redirect.returnUrl || ''
        }
      };
    } else {
      this.nextAction = null;
    }
    this.nextAction = data.attributes.next_action;
    this.status = data.attributes.status;
  }
}
