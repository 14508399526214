import React from 'react';

import './CampaignHeaderComponent.scss';

export default ({ description, image, name }) => {
  return (
    <div className="header">
      <div className="campaign-details-image">
        <img alt={name} src={image} className="image" />
      </div>
      <div className="campaign-details">
        <h1 className="campaign-name">{name}</h1>
        <p
          className="campaign-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};
