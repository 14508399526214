import React, { useRef, useEffect } from 'react';

import { Button, Icon } from '@paymongo/seeds';

import './SuccessfulDonateComponent.scss';

export default ({ slug, amount }) => {
  const ref = useRef(null);

  const handleDonateClick = () => {
    window.location.href = '/' + slug;
  };

  let formattedAmount = '';

  if (amount) {
    formattedAmount =
      ' of ₱ ' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  useEffect(() => {
    setTimeout(() => {
      ref.current.scrollIntoView({
        behavior: 'smooth'
      });
    }, 500);
  }, []);

  return (
    <div className="success-donate" ref={ref}>
      <Icon
        icon="checkCircle"
        size="xxl"
        style={{
          color: '#57B182',
          fontSize: '7rem'
        }}
      />
      <h3
        style={{
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
          textAlign: 'center'
        }}
      >
        Thank you for your donation{formattedAmount}! Your contribution will go
        a long way in helping the efforts of your beneficiaries achieve their
        benevolent purpose.
      </h3>
      <Button
        onClick={handleDonateClick}
        style={{
          paddingTop: '.5rem',
          paddingBottom: '.5rem'
        }}
      >
        Contribute Again
      </Button>
    </div>
  );
};
