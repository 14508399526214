import React, { useEffect, useRef } from 'react';

import { Button, Icon } from '@paymongo/seeds';

import './FailedDonateComponent.scss';

export default ({ slug }) => {
  const ref = useRef(null);

  const handleDonateClick = () => {
    window.location.href = '/' + slug;
  };

  useEffect(() => {
    setTimeout(() => {
      ref.current.scrollIntoView({
        behavior: 'smooth'
      });
    }, 500);
  }, []);

  return (
    <div className="failed-donate" ref={ref}>
      <Icon
        icon="warn"
        size="xxl"
        style={{
          color: '#E74439',
          fontSize: '7rem'
        }}
      />
      <h3
        style={{
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
          textAlign: 'center'
        }}
      >
        There was an error processing your donation, please try again.
      </h3>
      <Button
        onClick={handleDonateClick}
        style={{
          paddingTop: '.5rem',
          paddingBottom: '.5rem'
        }}
      >
        Go Back
      </Button>
    </div>
  );
};
