import React from 'react';

import { Icon } from '@paymongo/seeds';

import paymongoLogo from './paymongo-white.png';

import './FooterComponent.scss';

export default () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <Icon icon="lock" size="xs" className="lock-icon" />
          <span>Secured Payment</span>
        </div>
        <div className="right">
          <span
            style={{ fontWeight: 500, fontSize: '.7rem', color: '#FFFFFF' }}
          >
            Powered by{' '}
          </span>
          <img src={paymongoLogo} alt="PayMongo Logo" />
        </div>
      </div>
    </div>
  );
};
