import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { Input, Label } from '@paymongo/seeds';

import useMainPageStore from '@stores/useMainPageStore';

import './CreditCardFormComponent.scss';

export default observer(() => {
  const [mountClass, setMountClass] = useState('');

  const mainPageStore = useMainPageStore();

  const handleCardNumberChange = (e) => {
    const value = e.currentTarget.value;

    mainPageStore.card.setCardNumber(value.trim());
  };

  const handleExpMonthOnBlur = (e) => {
    const value = e.currentTarget.value;

    if (value.length === 1) {
      mainPageStore.card.setExpMonth(`0${value}`);
    }
  };

  const handleExpMonthChange = (e) => {
    const value = e.currentTarget.value;

    mainPageStore.card.setExpMonth(value);
  };

  const handleExpYearChange = (e) => {
    const value = e.currentTarget.value;

    mainPageStore.card.setExpYear(value);
  };

  const handleCvcChange = (e) => {
    const value = e.currentTarget.value;

    mainPageStore.card.setCvc(value);
  };

  useEffect(() => {
    setTimeout(() => {
      setMountClass('mounted');
    }, 200);
  }, []);

  return (
    <div className={`credit-card-form ${mountClass}`}>
      <h4>Credit Card Information</h4>
      <Cards
        number={mainPageStore.card.cardNumber.value}
        name=" "
        expiry={`${mainPageStore.card.expMonth.value}/${mainPageStore.card.expYear.value}`}
        cvc={`${mainPageStore.card.cvc.value}`}
        acceptedCards={['visa', 'mastercard']}
      />
      <div
        style={{
          marginTop: '2rem',
          height: 'fitContent'
        }}
      >
        <Input
          className={mainPageStore.card.cardNumber.error ? 'invalid' : ''}
          disabled={mainPageStore.isLoading}
          id="cardNumber"
          name="cardNumber"
          autoFocus={true}
          onChange={handleCardNumberChange}
          placeholder="Card Number"
          style={{
            width: '100%',
            paddingTop: '0.7rem',
            paddingBottom: '0.7rem'
          }}
          type="text"
          value={mainPageStore.card.cardNumber.value}
        />
        {mainPageStore.card.cardNumber.error && (
          <Label htmlFor="cardNumber" className="error-label height-100">
            {mainPageStore.card.cardNumber.error}
          </Label>
        )}
        <div
          style={{
            marginTop: '1rem',
            display: 'grid',
            gridTemplateColumns: '50% 50%'
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              marginRight: '.5rem'
            }}
          >
            <div style={{ marginRight: '.5rem' }}>
              <Input
                className={mainPageStore.card.expMonth.error ? 'invalid' : ''}
                disabled={mainPageStore.isLoading}
                id="expMonth"
                name="expMonth"
                onBlur={handleExpMonthOnBlur}
                onChange={handleExpMonthChange}
                placeholder="MM"
                style={{
                  width: '100%',
                  paddingTop: '0.7rem',
                  paddingBottom: '0.7rem',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  height: 'fit-content'
                }}
                value={mainPageStore.card.expMonth.value}
                type="text"
              />
            </div>
            <div style={{ marginLeft: '.5rem' }}>
              <Input
                className={mainPageStore.card.expYear.error ? 'invalid' : ''}
                disabled={mainPageStore.isLoading}
                id="expYear"
                name="expYear"
                onChange={handleExpYearChange}
                placeholder="YY"
                style={{
                  width: '100%',
                  paddingTop: '0.7rem',
                  paddingBottom: '0.7rem',
                  borderTopLeftRadius: 0,
                  borderLeft: 0,
                  borderBottomLeftRadius: 0,
                  height: 'fit-content'
                }}
                value={mainPageStore.card.expYear.value}
                type="text"
              />
            </div>
            <div style={{ gridColumn: '1/4', width: '100%' }}>
              {mainPageStore.card.expMonth.error && (
                <Label htmlFor="expMonth" className="error-label">
                  {mainPageStore.card.expMonth.error}
                </Label>
              )}
              {mainPageStore.card.expYear.error && (
                <Label htmlFor="expYear" className="error-label">
                  {mainPageStore.card.expYear.error}
                </Label>
              )}
            </div>
          </div>
          <div
            style={{
              marginLeft: '.5rem'
            }}
          >
            <Input
              className={mainPageStore.card.cvc.error ? 'invalid' : ''}
              disabled={mainPageStore.isLoading}
              id="cvc"
              name="cvc"
              onChange={handleCvcChange}
              placeholder="CVC"
              style={{
                width: '100%',
                paddingTop: '0.7rem',
                paddingBottom: '0.7rem'
              }}
              value={mainPageStore.card.cvc.value}
              type="text"
            />
            {mainPageStore.card.cvc.error && (
              <Label htmlFor="cvc" className="error-label">
                {mainPageStore.card.cvc.error}
              </Label>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
