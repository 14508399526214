import { observable, action } from 'mobx';

export default class FormField {
  @observable error = '';
  @observable value = '';

  @action setError(value) {
    this.error = value;
  }

  @action setValue(value) {
    this.value = value;
  }
}
