import React, { useEffect, useState } from 'react';

import { MessageBox } from '@paymongo/seeds';

import './RedirectionMessageComponent.scss';

export default ({ paymentMethod }) => {
  const [mountClass, setMountClass] = useState('');

  let paymentMethodText = '';

  if (paymentMethod === 'gcash') {
    paymentMethodText = 'GCash';
  } else if (paymentMethod === 'grab_pay') {
    paymentMethodText = 'GrabPay';
  }

  switch (paymentMethod) {
    case 'gcash':
      paymentMethodText = 'GCash';
      break;
    case 'grab_pay':
      paymentMethodText = 'GrabPay';
      break;
    case 'paymaya':
      paymentMethodText = 'Maya';
      break;
  }

  useEffect(() => {
    setTimeout(() => {
      setMountClass('mounted');
    }, 200);
  }, []);

  return (
    <div className="redirection-message-container">
      <MessageBox
        withIcon
        type="warning"
        className={`redirect-message ${mountClass}`}
      >
        {paymentMethodText} donation method will redirect to another page.
      </MessageBox>
    </div>
  );
};
