import React from 'react';

import NameTextfieldComponent from './components/name_textfield/NameTextfieldComponent';
import EmailTextfieldComponent from './components/email_textfield/EmailTextfieldComponent';

import './DonorInformationStepComponent.scss';

export default () => {
  return (
    <div className="donor-information-step">
      <h4>Donor Information</h4>
      <div>
        <div style={{ marginBottom: '.75rem' }}>
          <EmailTextfieldComponent />
        </div>
        <div>
          <NameTextfieldComponent />
        </div>
      </div>
    </div>
  );
};
