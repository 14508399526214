import React from 'react';

import './LoadingCampaignComponent.scss';

import { LoadingSpinner } from '@paymongo/seeds';

export default () => {
  return (
    <div className="loading-campaign">
      <LoadingSpinner />
      <p>Loading Campaign...</p>
    </div>
  );
};
